import React, { useState, useRef, useEffect } from "react";
import Select, { components } from "react-select";
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import loadingGif from "../../assets/images/load.gif";
import Defaultuser from "../../assets/images/defaultuser.png";

const MultiSelectDropdown = ({
  selectedOptions,
  onSelectChange,
  apiUrl,
  type,
}) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const selectRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.data) {
          setData(data.data);
          setFilteredData(data.data);
        }
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    };

    fetchData();
  }, [apiUrl, type]);

  useEffect(() => {
    const filtered = data.filter((item) =>
      item.name ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : item.size
    );

    setFilteredData(filtered);
  }, [searchTerm, data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const options = filteredData.map((item) => ({
    value: item.name ? item.name : item.size,
    label: item.name ? item.name : item.size,
    id: item.id ? item.id : item.size
  }));

  const handleSelectChange = (selectedOptions) => {
    if (type === 'country') {
      onSelectChange([{ value: selectedOptions.value, id: selectedOptions.id }], type);
    } else {
      const selectedValues = selectedOptions.map((option) => ({
        value: option.value,
        id: option.id,
      }));
      onSelectChange(selectedValues, type);
    }
  };

  // Custom option component with checkboxes
  const Option = ({ innerProps, label, isSelected }) => (
    <div {...innerProps}>
      <input type="checkbox" checked={isSelected} readOnly />
      {label}
    </div>
  );

  // Custom menu component with max height and overflow
  const Menu = (props) => {
    const maxHeight = 200;

    return (
      <components.Menu {...props}>
        <div style={{ maxHeight }}>{props.children}</div>
      </components.Menu>
    );
  };

  return (
    <div className="multi-select-dropdown" ref={selectRef}>
      <label>Select {`${type}`}</label>
      <Select
        isMulti={type !== 'country'}
        options={options}
        value={selectedOptions.map((option) => ({
          value: option.value,
          label: option.value,
          id: option.id,
        }))}
        onChange={handleSelectChange}
        onInputChange={setSearchTerm}
        placeholder={`Select ${type}...`}
        components={{ Option, Menu }}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        blurInputOnSelect
      />
    </div>
  );
};




const AllIcp = () => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [countryID, setCountryID] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const [ICPData, setICPData] = useState([]);
  const [uploadedICPData, setUploadedICPData] = useState([]);

  const fetchICP = async () => {
    try {
      const response = await axios.post("/user/getICPData",
        { "userId": userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem("auth_token")
          }
        }
      );


      const data = response.data.result.data;
      setICPData(data);

    } catch (error) {

    }
  }

  const fetchUploadedICP = async () => {
    try {
      const response = await axios.post("/user/getUploadedICPData",
        { "userId": userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem("auth_token")
          }
        }
      );


      const data = response.data.result.data[0].list;
      console.log(data)
      setUploadedICPData(data);
      // console.log(uploadedICPData)

    } catch (error) {

    }
  }

  useEffect(() => {
    console.log('function run')
    fetchICP()
    fetchUploadedICP()
  }, [])

  const countriesApiUrl = "https://api.klout.club/api/countries";
  const statesApiUrl = `https://api.klout.club/api/getStatesByCountryId/${countryID}`;
  const citiesApiUrl = "https://api.klout.club/api/cities";
  const jobtitlesApiUrl = "https://api.klout.club/api/job-titles";
  const industriesApiUrl = "https://api.klout.club/api/industries";
  const companiesApiUrl = "https://api.klout.club/api/companies";
  const employeeApiUrl = "https://api.klout.club/api/emloyeee-size";

  const handleSelectChange = (selectedValues, type) => {
    if (type === "country") {
      setSelectedCountries(selectedValues);
      setCountryID(selectedValues[0].id); // Assuming the first selected country ID
    } else if (type === "state") {
      setSelectedStates(selectedValues);
    } else if (type === "city") {
      setSelectedCities(selectedValues);
    } else if (type === "job-title") {
      setSelectedJobTitles(selectedValues);
    } else if (type === "industry") {
      setSelectedIndustries(selectedValues);
    } else if (type === "company") {
      setSelectedCompanies(selectedValues);
    } else if (type === "employee-size") {
      setSelectedEmployees(selectedValues);
    }
  };

  const transformSelectedItems = (items) => {
    return items.map((item) => ({
      id: item.id ? item.id : "",
      value: item.value,
    }));
  };

  const fetchData = async () => {
    const data = {
      user_id: userId,
      country_id: countryID.toString(),
      countries: transformSelectedItems(selectedCountries),
      companies: transformSelectedItems(selectedCompanies),
      employeeSize: transformSelectedItems(selectedEmployees),
      // skills: [], // Assuming skills are empty as they're not mentioned in your code
      industries: transformSelectedItems(selectedIndustries),
      states: transformSelectedItems(selectedStates),
      jobTitles: transformSelectedItems(selectedJobTitles),
    };

    try {
      const response = await axios.post("/user/saveICP",
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem('auth_token')
          }
        }
      );
      // Handle the response data as needed
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      <div className="row">
        <div
          className="col-12"
          style={{
            backgroundColor: "#fff",
            padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">ICP Search</h1>
            <Link
              to={`/admin/dashboard`}
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              style={{
                backgroundColor: "#F5007E",
                borderColor: "#F5007E",
                color: "white",
                borderRadius: "12px",
              }}
            >
              <i className="fa fa-solid fa-arrow-left"></i> &nbsp; Go Back
            </Link>
          </div>
        </div>
      </div>

      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">ICP</h6>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedCountries}
                  onSelectChange={(values, type) => handleSelectChange(values, "country")}
                  apiUrl={countriesApiUrl}
                  type="country"
                />
              </div>
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedStates}
                  onSelectChange={(values, type) => handleSelectChange(values, "state")}
                  apiUrl={statesApiUrl}
                  type="state"
                />
              </div>
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedJobTitles}
                  onSelectChange={(values, type) => handleSelectChange(values, "job-title")}
                  apiUrl={jobtitlesApiUrl}
                  type="job-title"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedIndustries}
                  onSelectChange={(values, type) => handleSelectChange(values, "industry")}
                  apiUrl={industriesApiUrl}
                  type="industry"
                />
              </div>
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedCompanies}
                  onSelectChange={(values, type) => handleSelectChange(values, "company")}
                  apiUrl={companiesApiUrl}
                  type="company"
                />
              </div>
              <div className="col-3">
                <MultiSelectDropdown
                  selectedOptions={selectedEmployees}
                  onSelectChange={(values, type) => handleSelectChange(values, "employee-size")}
                  apiUrl={employeeApiUrl}
                  type="employee-size"
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-3">
                <button
                  onClick={fetchData}
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Search ICP"}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Saved ICP</h6>
          </div>
          <div className="card-body">
            <div className="row">
              {
                ICPData.length > 0 ? <div>
                  {
                    ICPData.map((item) => {
                      return <><table key={item.id} className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Country</th>
                            <th>State</th>
                            <th>Company</th>
                            <th>Industry</th>
                            <th>Employee Size</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>{item.countries.map(item => item.value).join(', ')}</td>
                            <td>{item.states.map(item => item.value).join(', ')}</td>
                            <td>{item.companies.map(item => item.value).join(', ')}</td>
                            <td>{item.industries.map(item => item.value).join(', ')}</td>
                            <td>{item.employeeSize.map(item => item.value).join(', ')}</td>
                          </tr>
                        </tbody>
                      </table>
                        <br />
                        <br />
                      </>

                    })
                  }
                </div> : null
              }
            </div>

          </div>
        </div>
      </div>

      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Uploaded ICP</h6>
          </div>
          <div className="card-body">
            <div className="row">
              {
                uploadedICPData.length > 0 ? (
                  <div>
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Country</th>
                          <th>State</th>
                          <th>Company</th>
                          <th>Designation</th>
                          <th>Industry</th>
                          <th>Skill</th>
                          <th>Employee Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          uploadedICPData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.country || ""}</td>
                              <td>{item.state || " "}</td>
                              <td>{item.company || " "}</td>
                              <td>{item.designation || " "}</td>
                              <td>{item.industry || " "}</td>
                              <td>{item.skill || ""}</td>
                              <td>{item.employeeSize || ""}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    <br />
                    <br />
                  </div>
                ) : null
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AllIcp;

