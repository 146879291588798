import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

function AddMember() {
  const history = useHistory();

  const token = useSelector((state) => state.auth.token);

  const imageBaseUrl = process.env.REACT_APP_API_URL;

  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem('userId');

  const [searchResult, setSearchResult] = useState(null);

  const [formData, setFormData] = useState({
    emailId: "",
    mobileNumber: ""
  })

  function handleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try{
      const response = await axios.post('/user/userByEmailOrPhone',
        formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });


      if (response.data.status) {
        setErrors(null);
        const result = response.data.result;
        setSearchResult(result);
      }else{
        setSearchResult(null);
        setErrors('No Response Found')
      }
    }catch(error){
      setSearchResult(null);
      setErrors('No Response Found')
  }
  }

  const handleChangeSubadmin = async () => {
    const emailId = searchResult.emailId;
    const adminId = userId;

    const response = await axios.post('/user/assignSubadmin', 
      { emailId, adminId }, 
      { headers: {
        'Content-Type': 'application/json'
        } 
      });

    if(response.data.status){
      window.location.reload();
    }
    
  }


  return (
    <>
      <div className="row">
        <div
          className="col-12"
          style={{
            backgroundColor: "#fff",
            padding: "10px 20px",
            borderRadius: "10px",
          }}
        >
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Add Member </h1>
            <Link
              to={`/admin/all-member`}
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              style={{
                backgroundColor: "#F5007E",
                borderColor: "#F5007E",
                color: "white",
                borderRadius: "12px",
              }}
            >
              <i className="fa fa-solid fa-arrow-left"></i> &nbsp; Go Back
            </Link>
          </div>
        </div>
      </div>

      <div className="row p-3">
        <div className="col-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Add Member</h6>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit}>

                    <div className="row">
                      <div className="col-md-3">
                        <input
                          type="text"
                          placeholder="Email"
                          name="emailId"
                          onChange={handleChange}
                          value={formData.emailId}
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="mobileNumber"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn"
                          style={{ background: "#0000ff", color: "#fff" }}
                        >Search</button>
                      </div>
                    </div>

                  </form>
                </div>

                {searchResult &&
                  <div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Company</th>
                          <th scope="col">Role</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{searchResult.first_name + ' ' + searchResult.last_name}</td>
                          <td>{searchResult.designation}</td>
                          <td>{searchResult.company}</td>
                          <td>{searchResult.role}</td>
                          <td>{searchResult.role === 'subadmin' ? <button disabled className="btn btn-secondary">{searchResult.role}</button> : <button className="btn btn-primary" onClick={handleChangeSubadmin}>Change To subadmin</button>}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }

                {errors && 
                <div>
                  <div>{errors}</div>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMember;
