import React, { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import swal from "sweetalert";
import ReactPaginate from 'react-paginate';
import 'bootstrap/dist/css/bootstrap.min.css';
import './measureRoi.css';

import enhancedCustomSoundex from '../../utils/customSoundex'

const MeasureRoi = () => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('Choose File');
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadedCurrentPage, setUploadedCurrentPage] = useState(0);
  const entriesPerPage = 10;
  const [uploadedICPData, setUploadedICPData] = useState([]);
  const [uploadedHeaders, setUploadedHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCompanyCount, setTotalCompanyCount] = useState(0);
  const [matchedCompanyCount, setMatchedCompanyCount] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [companyWithDesignationCount, setCompanyWithDesignationCount] = useState(0);
  const [matchedCompanyAndDesignation, setMatchedCompanyAndDesignation] = useState([]);
  const [companyWithDesignationScore, setCompanyWithDesignationScore] = useState(0);
  const [companyHighestScore, setCompanyHighestScore] = useState(0);

  const userId = localStorage.getItem('userId');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(event.target.files[0].name);
    setFile(event.target.files[0]);
    if (!file) return;

    const reader = new FileReader();
    const fileType = file.type;

    reader.onload = (e) => {
      const binaryString = e.target.result;

      if (fileType.includes('csv') || file.name.endsWith('.csv')) {
        Papa.parse(binaryString, {
          header: true,
          complete: (results) => {
            setHeaders(results.meta.fields);
            setData(results.data);
          },
        });
      } else if (fileType.includes('spreadsheetml.sheet') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        setHeaders(sheetData[0]);
        setData(sheetData.slice(1).map(row => {
          const rowObject = {};
          row.forEach((cell, index) => {
            rowObject[sheetData[0][index]] = cell;
          });
          return rowObject;
        }));
      }
    };

    if (fileType.includes('csv') || file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else if (fileType.includes('spreadsheetml.sheet') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
      reader.readAsBinaryString(file);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleUploadedPageClick = ({ selected }) => {
    setUploadedCurrentPage(selected);
  };

  const fetchUploadedICP = async () => {
    try {
      const response = await axios.post("/user/getUploadedICPData",
        { "userId": userId },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': localStorage.getItem("auth_token")
          }
        }
      );
      const data = response.data.result.data[0].list;
      setUploadedICPData(data);

      if (data.length > 0) {
        const headers = Object.keys(data[0]).filter(header => header !== '_id');
        setUploadedHeaders(headers);
      }

    } catch (error) {
      console.error(error);
    }
  }

  const hadleUploadCompare = () => {
    setLoading(true)
    companyCompare();
    companyWithDesignationMatch();
  }

  const companyCompare = () => {
    // alert('running company compare');
    setTotalCompanyCount(uploadedICPData.length);
    const matchedData = [];
    let matchCount = 0;
    let companyScore = 0;
    const p1Score = 5;
    const p2Score = 3;
    const p3Score = 1;
    uploadedICPData.forEach((uploadItem) => {
      if(uploadItem.priority.toLowerCase() === "p1"){
        companyScore = companyScore + p1Score;
      }else if(uploadItem.priority.toLowerCase() === "p2"){
        companyScore = companyScore + p2Score;
      }else if(uploadItem.priority.toLowerCase() === "p3"){
        companyScore = companyScore + p3Score;
      }
        data.forEach((item) => {
            if(enhancedCustomSoundex(uploadItem.company) === enhancedCustomSoundex(item.company)){
              console.log('hello')
                // matchedData.push(uploadItem.company);
                let companyName = item.company.toLowerCase();
                matchedData.push(companyName);
                matchCount++;
            }
        })
    })
    setLoading(false);
    // Remove duplicates
    const uniqueMatchedData = [...new Set(matchedData)];
    setCompanyHighestScore(companyScore)
    setMatchedCompanyCount(uniqueMatchedData.length)
    setCompanyList(uniqueMatchedData)
    
  }

  const companyWithDesignationMatch = () => {
    // alert('running companyWithDesignationMatch')
    const matched = [];
    let matchCount = 0;
    let securedScore = 0;
    let priorityScore = 0;
    uploadedICPData.forEach((uploadItem) => {
        if(uploadItem.priority.toLowerCase() === "p1"){
          priorityScore = 5;
        }else if(uploadItem.priority.toLowerCase() === "p2"){
          priorityScore = 3;
        }else if(uploadItem.priority.toLowerCase() === "p3"){
          priorityScore = 1;
        }else{
          priorityScore = 0;
        }

      // console.log(data)
        data.forEach((item) => {
            if(enhancedCustomSoundex(uploadItem.company) === enhancedCustomSoundex(item.company)){
                const uploadDesignation = uploadItem.designation.split(', ');

                for(let i = 0; i < uploadDesignation.length; i++){
                  const word = uploadDesignation[i].toLowerCase();
                  const sentence = item.designation.toLowerCase();
                  const matchedWord = sentence.indexOf(word);

                  if(matchedWord > -1){


                    if(word === "ceo" || word === "cgo" || word === "coo" || word === "cfo" || word === "cio" || word === "cto" || word === "cmo" || word === "chro" || word === "cpo" || word === "cro" || word === "cco" || word === "cdo" || word === "cco" || word === "clo" || word === "cino" || word === "cso" || word === "cxo" || word === "cdo" || word === "cso" || word === "cao" || word === "cco" || word === "ciso" || word === "chief executive officer" || word === "chief growth officer" || word === "chief operating officer" || word === "chief financial officer" || word === "chief information officer" || word === "chief technology officer" || word === "chief marketing officer" || word === "chief human Resources officer" || word === "chief product officer" || word === "chief revenue officer" || word === "chief customer officer" || word === "chief data officer" || word === "chief compliance officer" || word === "chief risk officer" || word === "chief legal officer" || word === "chief innovation officer" || word === "chief strategy officer" || word === "chief experience officer" || word === "chief digital officer" || word === "chief sustainability officer" || word === "chief analytics officer" || word === "chief communications officer" || word === "chief security officer" || word === "chief information security officer"){
                      const score = 5;
                      securedScore = securedScore + priorityScore + score;
                    }else if(word === "vice president" || word === "vp"){
                      const score = 4;
                      securedScore = securedScore + priorityScore + score;
                    }else if(word === "director" || word === "general manager" || word === "gm" || word === "head"){
                      const score = 3;
                      securedScore = securedScore + priorityScore + score;
                    }else if(word === "manager"){
                      const score = 2;
                      securedScore = securedScore + priorityScore + score;
                    }else{
                      const score = 1;
                      securedScore = securedScore + priorityScore + score;
                    }
                    matched.push(`${item.company}---`);
                    matched.push(`${item.designation}, `);
                    matchCount++
                  }
                  

                    // if(item.designation.toLowerCase() === uploadDesignation[i].toLowerCase()){
                    //   matched.push(`${item.company}-`);
                    //   matched.push(`${item.designation}, `);
                    //     matchCount++
                    // }
                }
            }
        })
    })
    setLoading(false);
    // Remove duplicates
    // const uniqueMatchedData = [...new Set(matchedData)];
    // setMatchedCompanyCount(uniqueMatchedData.length)
    setCompanyWithDesignationCount(matchCount);
    setMatchedCompanyAndDesignation(matched);
    setCompanyWithDesignationScore(securedScore)
  }

  const currentData = data.slice(currentPage * entriesPerPage, (currentPage + 1) * entriesPerPage);
  const currentUploadedData = uploadedICPData.slice(uploadedCurrentPage * entriesPerPage, (uploadedCurrentPage + 1) * entriesPerPage);

  // Custom Soundex implementation
//   function customSoundex(name) {
//     if (!name) return '';

//     name = name.toUpperCase();

//     const encodingMap = {
//         B: '1', F: '1', P: '1', V: '1',
//         C: '2', G: '2', J: '2', K: '2', Q: '2', S: '2', X: '2', Z: '2',
//         D: '3', T: '3',
//         L: '4',
//         M: '5', N: '5',
//         R: '6'
//     };

//     const separators = ['A', 'E', 'I', 'O', 'U', 'H', 'W', 'Y'];

//     function getEncodedChar(char) {
//         return encodingMap[char] || '';
//     }

//     let encodedName = name[0];
//     let prevCode = getEncodedChar(name[0]);

//     for (let i = 1; i < name.length; i++) {
//         const char = name[i];
//         const code = getEncodedChar(char);

//         // Skip encoding for separators but reset the previous code
//         if (separators.includes(char)) {
//             prevCode = '';
//             continue;
//         }

//         if (code && code !== prevCode) {
//             encodedName += code;
//         }
//         prevCode = code;
//     }

//     // Ensure the Soundex code is exactly 4 characters long
//     encodedName = encodedName.substring(0, 1) + encodedName.substring(1).replace(/[^0-9]/g, ''); // Remove non-numeric characters after the first letter
//     encodedName = encodedName.padEnd(4, '0').substring(0, 4);

//     return encodedName;
// }

// function hashName(name) {
//     return name.split(/\s+/).filter(word => !["AND", "THE", "OF", "INC", "LTD", "LLC", "CORP", "COMPANY"].includes(word)).join('').length;
// }
// function enhancedCustomSoundex(name) {
//     return customSoundex(name) + hashName(name).toString();
// }




  return (
    <>
      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header d-flex justify-content-between py-3">
            <h4 className="m-0 font-weight-bold text-primary">Measure ROI</h4>
            <div className="custom-file col-md-6 col-12">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={handleFileUpload}
                accept=".csv, .xlsx, .xls"
              />
              <label className="custom-file-label" htmlFor="customFile">
                {fileName}
              </label>
            </div>
          </div>
          <div className="card-body">
            {data.length > 0 && (
              <>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      {headers.map((header, index) => (
                        <th key={index}>{header.toUpperCase()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {headers.map((header, colIndex) => (
                          <td key={colIndex}>{row[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={Math.ceil(data.length / entriesPerPage)}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* For uploaded Data Comparison */}
      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header d-flex justify-content-between py-3">
            <h4 className="m-0 font-weight-bold text-primary">Uploaded ROI</h4>
            <div className="custom-file col-md-6 col-12">
              <button className='btn btn-primary' onClick={fetchUploadedICP}>Fetch Uploaded ROI</button>
            </div>
          </div>
          <div className="card-body">

            {uploadedICPData.length > 0 && (
              <>
              {data.length > 0 && <button className='btn btn-success ms-auto d-flex' onClick={hadleUploadCompare}>{loading ? 'fetching..' :  'Compare'}</button>}
                
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      {uploadedHeaders.map((header, index) => (
                        <th key={index}>{header.toUpperCase()}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {currentUploadedData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {uploadedHeaders.map((header, colIndex) => (
                          <td key={colIndex}>{row[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={Math.ceil(uploadedICPData.length / entriesPerPage)}
                  onPageChange={handleUploadedPageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
          </div>
        </div>
      </div>

        {/* Result section */}
      <div className="row p-3">
        <div className="card shadow mb-4">
          <div className="card-header d-flex justify-content-between py-3">
            <h4 className="m-0 font-weight-bold text-primary">Result</h4>
          </div>
          <div className="card-body">
            <table className='table table-striped table-bordered'>
                <thead>
                    <tr>
                        <th>Total Companies</th>
                        <th>Matched Companies</th>
                        <th>Matched Companies with designation</th>
                        <th>Score</th>
                        <th>Value Percentage</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{totalCompanyCount}</td>
                        <td>{matchedCompanyCount} - {companyList.map(item => item).join(', ')}</td>
                        <td>{companyWithDesignationCount} - {matchedCompanyAndDesignation}</td>
                        <td>{companyWithDesignationScore}</td>
                        <td><strong>{(companyWithDesignationScore/((totalCompanyCount * 5) + companyHighestScore)) * 100} %</strong> </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeasureRoi;
