function customSoundex(name) {
    if (!name) return '';

    name = name.toUpperCase();

    const encodingMap = {
        B: '1', F: '1', P: '1', V: '1',
        C: '2', G: '2', J: '2', K: '2', Q: '2', S: '2', X: '2', Z: '2',
        D: '3', T: '3',
        L: '4',
        M: '5', N: '5',
        R: '6'
    };

    const separators = ['A', 'E', 'I', 'O', 'U', 'H', 'W', 'Y'];

    function getEncodedChar(char) {
        return encodingMap[char] || '';
    }

    let encodedName = name[0];
    let prevCode = getEncodedChar(name[0]);

    for (let i = 1; i < name.length; i++) {
        const char = name[i];
        const code = getEncodedChar(char);

        if (separators.includes(char)) {
            prevCode = '';
            continue;
        }

        if (code && code !== prevCode) {
            encodedName += code;
        }
        prevCode = code;
    }

    encodedName = encodedName.substring(0, 1) + encodedName.substring(1).replace(/[^0-9]/g, '');
    encodedName = encodedName.padEnd(4, '0').substring(0, 4);

    return encodedName;
}

function hashName(name) {
    if (!name) return 0;

    const words = name.split(/\s+/)
        .filter(word => !["AND", "THE", "OF", "INC", "LTD", "LLC", "CORP", "COMPANY", "PVT"].includes(word.toUpperCase()))
        .map(word => word.toLowerCase())
        .sort();

    const hash = words.reduce((acc, word) => {
        return acc + word.split('').reduce((charAcc, char) => charAcc + char.charCodeAt(0), 0);
    }, 0);

    return hash;
}

function enhancedCustomSoundex(name) {
    if (!name) return '';

    return customSoundex(name) + hashName(name).toString();
}

export default enhancedCustomSoundex;
